import { PrismicError } from "@prismicio/client";
import { SliceZone } from "@prismicio/react";
import {
  type GetStaticProps,
  type InferGetStaticPropsType,
  type NextPage,
} from "next";
import { createClient } from "prismicio";
import { type FooterDocument, type PageDocument } from "prismicio-types";
import { components } from "slices";
import { Container } from "~/coreUI/Layout/Container/Container";
import { PageLayout } from "~/core/components/Layout/PageLayout";
import { SeoMeta } from "~/coreUI/Content/SeoMeta/SeoMeta";
import { pricingSnippetQuery } from "~/featUI/Pricing/utils";

const Page: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  page,
  footer,
}) => {
  return (
    <PageLayout footer={footer} isPublic>
      <Container yPadding="md" className="">
        {page && page?.data && (
          <>
            <SeoMeta {...page} />
            <SliceZone
              slices={page.data.slices}
              components={components}
              context
            />
          </>
        )}
      </Container>
    </PageLayout>
  );
};

export default Page;

export const getStaticProps: GetStaticProps<{
  page: PageDocument;
  footer: FooterDocument;
}> = async ({ previewData, params }) => {
  const client = createClient({
    previewData,
  });

  try {
    const [page, footer] = await Promise.all([
      client.getByUID("page", params?.uid as string, {
        fetchLinks: ["plan.uid"],
        graphQuery: pricingSnippetQuery,
      }),
      client.getSingle("footer", {
        fetchLinks: ["page.title"],
      }),
    ]);

    return {
      props: { page, footer },
    };
  } catch (e) {
    if (
      e instanceof PrismicError &&
      e.message.match(/no documents were returned/i)
    ) {
      return {
        notFound: true,
        revalidate: 1,
      };
    }
    throw e;
  }
};

export async function getStaticPaths() {
  const client = createClient();

  const pages = await client.getAllByType("page");

  return {
    paths: pages.map((page) => {
      return { params: { uid: page.uid } };
    }),
    fallback: true,
  };
}
